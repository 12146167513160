<template>
	<div class="wrap">
		<h3 class="title">绑定移动工作台</h3>
		<img :src="qrCode || require('@/assets/qrcode-empty.png')" alt="二维码" class="qr-code" @click="getQRCode(1)" />
		<h3 class="tips">微信绑定后可在移动端小程序使用订个包间相关功能</h3>
		<div class="is-biding">当前已绑定{{ list.length }}人</div>
		<div class="table-title">已绑定的人</div>
		<a-table :columns="columns" :data-source="list" rowKey="id">
			<template #action="record">
				<a @click="unBind(record)">移除</a>
				<a style="margin-left: 20px" @click="setAuth(record)">设置权限</a>
			</template>
			<template #avatar="avatar">
				<img :src="avatar" alt="头像" class="user-avatar" />
			</template>
		</a-table>
		<a-modal
			width="680px"
			:visible.sync="authVisible"
			title="设置权限"
			centered
			cancelText="取消"
			@ok="confirmAuth"
			@cancel="
				$refs.authModel.clearValidate()
				authVisible = false
				authForm = {
					auth: ''
				}
			">
			<a-form-model ref="authModel" :model="authForm" :label-col="{span: 5}" :wrapper-col="{span: 17}" :rules="rules">
				<a-form-model-item label="权限" prop="auth">
					<a-select placeholder="请设置权限" v-model="authForm.auth" :options="authOptions"></a-select>
				</a-form-model-item>
				<a-form-model-item label="职位" prop="position_name">
					<a-input v-model="authForm.position_name" placeholder="建议职位不超过4个字"> </a-input>
				</a-form-model-item>
				<a-form-model-item label="手机号" prop="phone">
					<a-input v-model="authForm.phone" placeholder="请输入手机号"> </a-input>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import {menuNeedObj, readDicFn} from '@/utils/utils'
import {getIsBindingList, getWorkMakeMiniQrcode, unbundlingUser, setPermission} from '@/api/mobileWorkbench'
import {getPositionList} from '@/api/position'
import {mapState} from 'vuex'
import _ from 'lodash'

export default {
	name: 'MobileWorkbench',
	data() {
		return {
			authVisible: false,
			authForm: {
				auth: '',
				phone: '',
				position_name: ''
			},
			columns: [
				{
					title: '序号',
					dataIndex: 'id'
				},
				{
					title: '微信头像',
					dataIndex: 'mini_user.avatar',
					scopedSlots: {customRender: 'avatar'}
				},
				{
					title: '微信昵称',
					dataIndex: 'mini_user.nickname',
					scopedSlots: {customRender: 'nickname'}
					// customRender: (name, record) => (record. nickname? record. nickname : record. mini_user.nickname),
				},
				{
					title: '手机号',
					dataIndex: 'mini_user.phone',
					scopedSlots: {customRender: 'phone'}
				},
				{
					title: '职位',
					dataIndex: 'mini_user.position_name',
					scopedSlots: {customRender: 'position_name'}
				},
				{
					title: '权限',
					dataIndex: 'userAuth',
					customRender: userAuth => readDicFn(userAuth, this.authOptions)
				},
				{
					title: '操作',
					key: 'action',
					scopedSlots: {customRender: 'action'}
				}
			],
			list: [],
			qrCode: '',
			currentUser: {},
			authOptions: [
				// 权限options
				{label: '员工', value: 0},
				{label: '店长', value: 1},
				{label: '老板', value: 2},
				{label: '前台', value: 3},
				{label: '员工(仅查看)', value: 4}
			],
			positionLists: [], // 职位options
			rules: {
				auth: [{required: true, message: '请设置权限', trigger: 'change'}],
				position_name: [{required: true, message: '请输入职位！', trigger: 'change'}],
				phone: [
					{
						required: true,
						message: '请输入手机号！',
						trigger: 'blur'
					}
					// {
					//   pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
					//   message: '请输入正确的手机号！',
					//   trigger: 'blur',
					// },
				]
			}
		}
	},
	computed: {
		...mapState('user', ['info'])
		//   rules: function() {
		//     let ruleDic = {
		//       auth: [
		//         { required: true, message: '请设置权限', trigger: 'change' },
		//       ],
		//       phone: [
		//         { required: true, message: '请输入手机号！', trigger: 'blur' },
		//         { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号！', trigger: 'blur' }
		//       ]
		//     }
		//     this.$refs.authModel && this.$refs.authModel.clearValidate()
		//     if (!this.authForm.auth) {
		//       return { auth:  ruleDic.auth }
		//     }
		//     else return ruleDic
		//   }
	},
	mounted() {
		this.getList()
		this.getQRCode()
		this.getJobOptions()
	},
	methods: {
		getList() {
			this.$store.dispatch('loading/startContentLoading')
			getIsBindingList().then(res => {
				if (res.code === 0) {
					// 映射对象
					const menu = {
						id: 'id',
						avatar: 'avatar',
						nickname: 'nickname',
						openid: 'openid',
						phone: 'phone',
						miniUser: 'mini_user',
						userAuth: 'permission',
						position_id: 'position_id',
						position_name: 'position_name'
					}

					// 处理返回数据, 进行数据判断
					const resData = (res.data || []).map(item => {
						const backItem = menuNeedObj(item, menu)

						backItem.mini_user = {}
						Object.keys(menu).forEach(keyItem => {
							backItem.mini_user[keyItem] = backItem[keyItem] || backItem.miniUser[keyItem]
						})
						return backItem
					})

					// 表格赋值处理后的数据
					this.list = resData
				}
				this.$store.dispatch('loading/endContentLoading')
			})
		},

		// 获取二维码图片链接
		getQRCode(refresh = 0) {
			const _this = this
			// 拼装入参
			const params = {
				page: 'work_pages/pages/bindingWorkbench/bindingWorkbench',
				params: `shop_id=${_this.info.shop.id}`,
				width: 200
			}

			// 通过公共二维码获取接口获取二维码图片链接
			getWorkMakeMiniQrcode(params).then(res => {
				if (res.code === 0) {
					this.qrCode = res.data
					if (refresh === 1) {
						this.$message.success('刷新成功！')
					}
				}
			})
		},

		// 获取职位列表
		getJobOptions() {
			getPositionList().then(res => {
				if (res.code === 0) {
					this.positionLists = (res.data || []).map(item => menuNeedObj(item, {label: 'name', value: 'id'}))
				}
			})
		},

		unBind(record) {
			this.$confirm({
				title: '提示',
				content: '确定移除用户吗？',
				onOk: () => {
					this.$message.loading({content: '移除中', key: 'unBind'})
					unbundlingUser({openid: record.openid}).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '移除成功！',
								key: 'unBind'
							})
							this.getList()
						}
					})
				}
			})
		},
		setAuth(record) {
			this.authVisible = true
			this.authForm.auth = record.userAuth
			this.authForm.phone = record.phone
			this.authForm.position_name = record.position_name
			this.currentUser = _.cloneDeep(record)
		},

		confirmAuth() {
			this.$refs.authModel
				.validate()
				.then(() => {
					setPermission({
						openid: this.currentUser.openid,
						phone: this.authForm.phone ? this.authForm.phone : '',
						permission: this.authForm.auth,
						position_name: this.authForm.position_name
					}).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '设置成功',
								key: 'category'
							})
							this.authVisible = false
							this.getList()
						}
					})
				})
				.catch(err => err)
		}
	}
}
</script>

<style scoped>
.wrap {
	overflow: hidden;
	padding: 0 22px;
}

.title {
	margin-top: 40px;
	font-weight: 500;
	font-size: 20px;
	color: #333333;
	text-align: center;
}

.qr-code {
	width: 200px;
	height: 200px;
	margin: 15px auto;
	cursor: pointer;
}

.tips {
	font-size: 14px;
	color: #333333;
	line-height: 16px;
	text-align: center;
}

.is-biding {
	width: 160px;
	height: 32px;
	background: #fff4f3;
	border-radius: 2px;
	font-weight: 400;
	font-size: 14px;
	color: #e63e30;
	line-height: 22px;
	margin: 15px auto 29px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.table-title {
	font-weight: 500;
	font-size: 16px;
	color: #333333;
	margin-bottom: 20px;
}

.user-avatar {
	width: 48px;
	height: 48px;
	border-radius: 50%;
}
</style>
